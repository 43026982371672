/** shared variables **/

$font: 'Montserrat', sans-serif;

$white: #FFFFFF;
$red: #F04923;
$grey: #C0C1C2;
$black: #383A39;
$bg-colour: $grey;
$primary: $white;
$secondary: $red;
$hr: lighten(desaturate(adjust-hue($bg-colour, -217), 26.53), 34.31);