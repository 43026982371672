@import "vars";
@import '~@splidejs/splide/css';

html {
    color: #222;
    font-size: 1em;
    line-height: 1;
}

::-moz-selection {
    background: #b3d4fc;
    text-shadow: none;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
    height: 200px;
}

.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}
input,
select,
textarea,
button {
    border-radius:0; /* reset safari round corners */
    border: none;
}


/* ==========================================================================
   Author's custom styles
   ========================================================================== */
html,
body,
main {
    position: relative;
    // width: 100%;
    // height: 100%;
    line-height: 1.2;
}
.site-body {
    height: 100%;
}
body {
    padding:0;
    margin:0;
    font-size:16px;
}

img {
    border:none;
}

/* ==========================================================================
   Holding Page
   ========================================================================== */
#about {
    position: static;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    .tile {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-repeat: repeat;
        background-size: 550px;
        opacity: 0.1;
    }
}
.contentwrap {
    width: 100%;
    max-width: 1364px;
    margin: 0 auto;
    position:relative;
    padding:20px;
    box-sizing: border-box;
}
.logo-wrapper{
    margin-right: 2rem;
}
.logo {
    display: block;
    width: 100%;
    height:auto;
    h1 {
        margin: 0px;
    }
}

/** contact details **/
.contact-details {
    padding: 30px 0px;

    margin: 5px 0px;
    a {
        text-decoration: none;
        color: inherit;
    }
    p {
        margin: 5px 0;
    }
}
.opening-hours {
    display: block;
}

/** site links **/
.site-links {
    margin-top: 2rem;
    gap:1rem;
    display: flex;
}
.site-link {
    position: relative;
    display: inline-block;
    width: 24px;
    height: 24px;
    box-sizing: border-box;

    svg {
        position: relative;
        width: auto;
        max-height: 100%;
        path {
            fill: $primary;
            transition: fill 0.3s ease;
        }
        &:hover {
            path {
                fill: $secondary;
            }
        }
    }
}

/** form generic **/
form {
    width: 100%;
    box-sizing: border-box;
    p {
        position: relative;
        margin: 15px 0;
    }
    button {
        float:right;
        margin-left:20px;
        margin-bottom:20px;
    }
}
form
label.float,
input {
    height: 56px;
    line-height: 56px;
}
label.error {
    display: block;
    font-size: 12px;
    line-height: 1;
}
input,
textarea {
    text-align: left;
    padding-left: 20px;
    font-family: $font;
    font-size: 16px;
    width: 100%;
    color: $secondary;
    background-color: transparent;
    box-sizing: border-box;
    border: 1px solid #929496;
    transition: border-color 0.3s ease;
    padding-top:5px;
}
textarea {
    padding-top: 15px;
    min-height: 100px;
}
label.float {
    position: absolute;
    left:0;
    color: #929496;
    padding-left: 20px;
    max-width: 100%;
    box-sizing: border-box;
    transition: all 1s ease;
    z-index: 1;
}
.float-wrap {
    &.focused, &.populated {
        label.float {
            top:2px;
            left: 5px;
            padding-left: 0;
            line-height: 14px;
            font-size: 0.75em;
            color: $secondary;
        }
        input, textarea{
            border-color: $secondary;
            outline: $secondary;
        }
    }
}
.recaptcha-notice {
    margin-top: 2rem;
    color: $hr;
    a {
        color: inherit;
    }
}
.splash{
    min-height: 100vh;
    height: auto;
    position: relative;
    background-color: rgba(0,0,0,.75);
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: multiply;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-position: center center;
    .header-section{
        position: absolute;
        top:0;
        left:0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        padding:20px;
    }
    .splash-content{

        display: grid;
        grid-template-areas:
        "line title"
        ". button";
        grid-template-columns:minmax(20px, 220px) minmax(200px, 475px);
        align-items: center;
        padding-top: 20vw;
        padding-right: 20px;
        grid-gap:2rem clamp(1rem, 3vw, 3rem);
        .title{
            font-size: clamp(22px, 4vw, 44px);
            max-width: 475px;
            grid-area: title;
        }
        .line{
            border:solid 4px #707070;
            max-height: 0px;
            // margin-right: clamp(.5rem, 2vw, 3rem);
            grid-area: line;
        }
        .button{
            grid-area: button;
        }
    }
}
a{
    text-decoration: none;
    color:inherit;
    transition: color 0.3s ease;
    &:hover{
        color:$red;
    }
}
nav.main-menu{
    display: flex;
    gap:clamp(1rem, 3vw, 3rem);
    align-items: center;
    margin-left: auto;
    font-weight:500;
    font-size: 17px;
}
.scroller{
    padding:clamp(3rem, 5vw, 6rem) 0;
}
.boat-card{
    cursor: pointer;
    border-left:1px solid #707070;
    padding:0 2rem;
    color: $black;

    picture{
        display: flex;
        justify-content: center;
    }
    img{
        align-self: center;
        height: 30vw!important;
        width: auto!important;
    }
    .title{
        font-size: clamp(22px, 4vw, 31px);
        margin-top: 0;
        margin-bottom: 1rem;
        font-family: 'Neuropolitical';
    }
    .subtitle{
        font-size: clamp(16px, 3vw, 18px);
        font-weight: 500;
        margin-top: 0;
    }
}
.two-col{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}
#about{
    padding:clamp(3rem, 5vw, 6rem) 0;
    background-color: $black;
    position: relative;
    .heading{
        font-size: 17px;
        color: $red;
    }
    .background{
        position: absolute;
        inset:0;
        z-index: 0;
        pointer-events: none;
        img{
            opacity: .3;
            height: 100%!important;
            width: 100%;
            object-fit: cover;
            mix-blend-mode: overlay;
            @media screen and (max-width:1200px) {
                object-fit: contain;
            }
        }
    }
    .main-content{
        position: relative;
        z-index: 1;
        display: grid;
        grid-template-columns: auto minmax(100px, 682px) minmax(100px, 682px) auto;
        grid-template-areas: 
        "line summary content ."
        ". button content ."
        ". . content .";
        @media screen and (max-width:760px) {
            grid-template-columns: 1fr;
            grid-template-areas: 
            "summary"
            "content"
            "button";
            text-align: center;
        }
        .line{
            border-top:solid 4px #707070;
            border-bottom:solid 4px #707070;
            max-height: 0px;
            grid-area: line;
            align-self: center;
        }
        .summary{
            grid-area: summary;
            font-size: clamp(22px, 4vw, 44px);
            padding:20px;
        }
        & > a.button{
            grid-area: button;
            margin:0 20px;
        }
        .editable-content{
            grid-area: content;
            padding:20px;
        }
        .why-hdpe {
            grid-column: 2 / 4;
            padding:20px;
            @media screen and (max-width:760px) {
                grid-column: 1 / 2;
            }
        }
    }
    @media screen and (max-width:760px) {
        text-align: center;
        .main-content > a.button{
            display: none;
        }
    }
}
#contact{
    padding-top:clamp(8rem, 12vw, 12rem);
    padding-bottom:14rem;
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: cover;
    background-color: rgba(0,0,0,.77);
    background-blend-mode: multiply;
    @media screen and (max-width:760px) {
        padding-bottom:max(150px, 30vw);
    }
    .heading{
        font-size: 17px;
        color: $red;
    }
    .main-content{
        position: relative;
        z-index: 1;
        display: grid;
        grid-template-columns: auto minmax(100px, 682px) minmax(100px, 682px) auto;
        grid-template-areas: 
        ". title form ."
        "line logo form ."
        ". details form ."
        ". details form .";
        @media screen and (max-width:760px) {
            grid-template-columns: 1fr;
            grid-template-areas: 
            "title"
            "logo"
            "details"
            "form";
            text-align: center;
            // padding-bottom: 5rem;
            .contact-logo, .site-links{
                justify-content: center!important;
            }
        }
        .heading{
            grid-area: title;
            padding:0 20px;
            margin:0;
        }
        .line{
            border-top:solid 4px #707070;
            border-bottom:solid 4px #707070;
            max-height: 0px;
            grid-area: line;
            align-self: center;
        }
        .contact-details{
            grid-area: details;
            font-size: clamp(16px, 3vw, 18px);
            padding:20px;
            .footer-address{
                margin-top: 2rem;
            }
        }
        .contact-form{
            grid-area: form;
            padding:20px;
        }
        .contact-logo{
            grid-area: logo;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
        
    }
}
.footer-aip{
    content: url('../assets/aip.svg');
    min-width: 200px;
    width:50%;
    max-width:440px;
    height: auto;
    position: absolute;
    left:-20px;
    bottom:0;
}

.site-link svg path {
    fill:#929496;
}
/* ==========================================================================
   Media Queries
   ========================================================================== */


@media only screen and (max-width: 1200px){
    #content {
        min-height: 100%;
    }
}

@media only screen and (max-width: 760px) {
    a {
        /* These are technically the same, but use both */
        overflow-wrap: break-word;
        word-wrap: break-word;

        -ms-word-break: break-all;
        /* This is the dangerous one in WebKit, as it breaks things wherever */
        word-break: break-all;
        /* Instead use this non-standard one: */
        word-break: break-word;

        /* Adds a hyphen where the word breaks, if supported (No Blink) */
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;
    }

}


@import "helpers";
@import "print";
@import "mobile_menu";